<template>
  <v-card class="rounded-card home-widget" flat outlined>
    <v-card-title class="py-2 justify-center">
      <h3 class="primary--text">
        <v-icon left class="mb-2" color="primary" small>mdi-bullseye-arrow</v-icon>
        {{ $t('layout.home.goals') }}
      </h3>
    </v-card-title>
    <VuePerfectScrollbar class="scroll-area">
      <v-card-text class="pt-0 pb-2">
        <template v-if="goals && goals.count !== 0">
          <v-card class="d-flex align-stretch my-2" flat
            v-for="goal in goals.items" :key="'goal_'+goal.id">
            <v-card-text class="px-5">
                <p class="col-12 text-start pa-0 my-0 subtitle font-weight-bold">
                  <v-tooltip top v-if="progressColor(goal.progress, goal.endDate) === 'error'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" v-bind="attrs" v-on="on" small>mdi-alert-circle-outline</v-icon>
                    </template>
                    <span>{{ $t("messages.home.goalDeadline") }}</span>
                  </v-tooltip>
                  {{ goal.title }}
                </p>
                <p class="col-7 pa-0 my-0 caption">{{ goal.endDate }}</p>
                <p class="col-5 text-end pa-0 my-0 caption">
                  {{ goal.progress }}%
                </p>
              <v-progress-linear rounded height="10" :color="progressColor(goal.progress, goal.endDate)"
                :value="goal.progress"></v-progress-linear>
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <div class="px-3 text-center">
            {{ $t("messages.emptyState", {entity: $tc("models.goal.entity")}) }}
          </div>
        </template>
      </v-card-text>
    </VuePerfectScrollbar>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from 'moment';
export default {
  computed: mapGetters(['goals']),
  data() {
    return {
      moment
    };
  },
  methods: {
    progressColor(progress, endDate) {
      return +progress === +100 ? 'success' : this.moment(endDate, 'DD MMM YYYY').diff(this.moment(), 'days') > 10 ? 'secondary' : 'error';
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 30px !important;
}
p {
  display: inline-block;
}
</style>
