<template>
  <v-card flat outlined class="home-widget">
    <VuePerfectScrollbar class="scroll-area row pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-card-text v-for="(office) in countryOffices" :key="office.id"
          class="col-sm-6 col-12 text-center clock-container">
          <app-clock :timezone="office.timezone" :event="events && events[`${calendar.OFFICE_HOLIDAYS}_${office.id}`] ?
            events[`${calendar.OFFICE_HOLIDAYS}_${office.id}`][0].title : null" />
        </v-card-text>
      </v-row>
    </VuePerfectScrollbar>
  </v-card>
</template>
<script>
import {mapGetters} from 'vuex';
import {calendar} from 'constants';
import _ from 'lodash';
export default {
  computed: {
    ...mapGetters(['offices']),
    countryOffices() {
      return _.uniqBy(this.offices.items, (office) => office.timezone);
    }
  },
  data() {
    return {
      calendar
    };
  },
  props: {
    events: {
      type: Object
    }
  }
};
</script>
<style lang="scss" scoped>
.clock-container {
  margin: auto;
}
</style>
