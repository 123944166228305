<template>
  <v-card class="rounded-card home-widget" flat outlined>
    <VuePerfectScrollbar class="scroll-area">
      <v-card-title class="py-2 justify-center">
        <h3 class="primary--text">
          <v-icon left class="mb-2" color="primary" small>mdi-trophy-award</v-icon>
          {{ $t('layout.home.kudos') }}
        </h3>
      </v-card-title>
      <v-card-text>
        <template v-if="weeklyKudos && weeklyKudos.count !== 0">
          <v-row>
            <v-col cols="6">
              <h3 class="px-1">Given by:</h3>
            </v-col>
            <v-col cols="6">
              <h3>Given To:</h3>
            </v-col>
          </v-row>
          <v-card class="d-flex align-stretch" flat
            v-for="kudo in weeklyKudos.items" :key="'kudo_'+kudo.id">
            <v-card-text class="px-1">
              <v-chip small>
                {{ kudo.creator_name }}
              </v-chip>
            </v-card-text>
            <v-card-text class="px-2">
              <v-chip small>
                {{ kudo.assignee_name }}
              </v-chip>
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <div class="px-3 text-center">
            {{ $t("messages.emptyState", {entity: $tc("models.kudo.entity")}) }}
          </div>
        </template>
      </v-card-text>
    </VuePerfectScrollbar>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  computed: {
    ...mapGetters(['weeklyKudos'])
  }
};
</script>
