<template>
<v-card class="rounded-card home-widget" flat outlined>
  <v-card-title class="py-2 justify-center">
    <h3 class="primary--text">
      <v-icon left class="mb-2" color="primary" small>mdi-timer</v-icon>
        {{ $t('layout.home.worklogSummary', {month: moment().format('MMMM')}) }}
    </h3>
  </v-card-title>
  <v-card-subtitle class="pt-3 text-center">
    <h4>
      <v-chip small>
        {{ $t('layout.home.totalWorkingDays', {days: statistics.workingDays, hours: statistics.workingHours}) }}
      </v-chip>
    </h4>
  </v-card-subtitle>
  <VuePerfectScrollbar class="scroll-area">
    <v-card-text class="pt-0 pb-2">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th class="text-right">{{ statistics.totalWorklogs }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in statistics.summary" :key="item.project">
              <td>
                <span v-if="item.project">{{ item.project }}</span>
                <span v-else class="text-muted-light">{{ $t('views.constants.noProject') }}</span>
              </td>
              <td class="text-right">
              </td>
              <td class="text-right">
                <h4>{{ item.duration }}</h4>
                <small>{{ _.round(((item.durationInSec * 100) / statistics.totalWorklogsInSec), 2) }} %</small>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </VuePerfectScrollbar>
</v-card>
</template>
<script>
import {mapGetters} from 'vuex';
import moment from 'moment';
export default {
  computed: mapGetters(['statistics']),
  data() {
    return {
      moment
    };
  }
};
</script>
