<template>
  <v-card class="rounded-card home-widget" flat outlined>
    <v-card-title class="py-2 justify-center">
      <h3 class="primary--text">
        <v-icon left class="mb-2" color="primary" small>{{ icon }}</v-icon>
        {{ title }}
      </h3>
    </v-card-title>
    <template v-if="!_.isEmpty(records)">
      <VuePerfectScrollbar class="scroll-area">
        <v-card-text class="pt-0 px-0 pb-2 fill-height">
          <v-list dense>
            <v-list-item
              v-for="record in records"
              :key="record.id"
              class="my-0 py-0">
              <v-list-item-content class="justify-center py-0">
                {{ record.title }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </VuePerfectScrollbar>
    </template>
    <template v-else>
      <div class="px-3 text-center">
        {{ emptyMessage }}
      </div>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'ListCard',
  props: {
    records: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    emptyMessage: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 30px !important;
}
.v-list-item__content {
  text-align: center !important;
}
.v-card__title {
  word-break: normal !important;
  text-align: center;
}
</style>

