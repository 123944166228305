<template>
  <v-card class="col-12 mt-md-3 rounded-card home-widget" flat outlined>
    <v-card-title class="pt-0 justify-center">
      <h3 class="primary--text">
        <v-icon left class="mb-2" color="primary" small>mdi-beach</v-icon>
        {{ $t('layout.home.leaves') }}
      </h3>
    </v-card-title>
    <template v-if="!_.isEmpty(records)">
      <VuePerfectScrollbar class="scroll-area">
        <v-card-text class="pt-0">
          <v-list dense disabled v-for="key in _.keys(records)" :key="key">
            <h4>{{ key }}</h4>
            <v-list-item-group class="align-items-center">
              <v-list-item v-for="request in records[key]" :key="'request_'+request.id">
                <span class="mr-2">
                  {{ _.replace(request.title, /\d*:\d*[p|a][m]/gm, '') }}
                </span>
                <v-spacer/>
                <span class="caption">
                  <template v-if="request.details.unit === userLeaveUnits.HOURS">
                    <v-chip small>
                      {{ moment(request.start).format('hh:mm A') }} - {{ moment(request.end).format('hh:mm A') }}
                    </v-chip>
                  </template>
                  <template v-else>
                    <v-chip small>
                    {{ $t(`models.leaveRequest.attributes.units.${request.details.unit}`) }}
                    </v-chip>
                  </template>
                </span>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
          </v-list>
        </v-card-text>
      </VuePerfectScrollbar>
    </template>
    <template v-else>
      <div class="px-3 text-center">
        {{ $t("messages.home.noLeaves") }}
      </div>
    </template>
  </v-card>
</template>

<script>
import {userLeaveUnits} from 'constants';
import moment from 'moment';

export default {
  name: 'LeavesCard',
  props: {
    records: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      userLeaveUnits,
      moment
    };
  }
};
</script>

<style lang="scss" scoped>
.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 30px !important;
}
.v-card__title {
  word-break: normal !important;
  text-align: center;
}
</style>
